import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
// import dataSuper from "../hooks/Conf";
import Footer from "./Footer";
import dataContext from "../context/User";
// import { Alert } from "bootstrap";

const EventosAulas = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [eventos, setEventos] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tipo, setTipo] = useState('Z');
  const [areasID, setAreasID] = useState(0);
  const [inicio, setInicio] = useState();
  const [fin, setFin] = useState();
  const [mail, setMail] = useState();
  const [bandera, setBandera] = useState(0);

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
  } else {
    history.push("/");
  }

  const formatFecha = (fechaFormat) => {
    const fecha = new Date(fechaFormat);
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate() + 1;
    if (mes < 10) {
      mes = "0" + mes;
    }
    if (dia < 10) {
      dia = "0" + dia;
    }
    return dia + "-" + mes + "-" + year;
  };

  useEffect(() => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    let mes = fecha.getMonth() + 1;
    let dia = fecha.getDate();
    if (mes < 10) {
      mes = "0" + mes;
    }
    if (dia < 10) {
      dia = "0" + dia;
    }
    const fechaFinal = year + "-" + mes + "-" + dia;
    const dataForm = new FormData();
    setInicio(fechaFinal);
    setFin(fechaFinal);
    dataForm.append("inicio", fechaFinal);
    dataForm.append("fin", fechaFinal);
    GetFetch.getEventosAulas(dataForm).then((data) => {
      setEventos(data.result);
      data?.result ? setBandera(1) : setBandera(0);
    });

    GetFetch.getEventosAulasAreas(dataForm).then((data) => {
      setAreas(data.result);
    });

    loadMenu();
  }, []);

  const inicioSet = (e) => {
    setInicio(e.target.value);
  };

  const finSet = (e) => {
    setFin(e.target.value);
  };
  const areaSet = (e) => {
    setAreasID(e.target.value);
  };
  const tipoSet = (e) => {
    setTipo(e.target.value);
  };
  const mailSet = (e) => {
    setMail(e.target.value);
  };

  const btnConsultar = () => {
    const dataForm = new FormData();
    dataForm.append("inicio", inicio);
    dataForm.append("fin", fin);
    dataForm.append("areaID", areasID);
    dataForm.append("tipo", tipo);

    if (+areasID === 0 && tipo === 'Z') {
      GetFetch.getEventosAulas(dataForm).then((data) => {
        setEventos(data.result);
        data?.result ? setBandera(1) : setBandera(0);
      });
    }

    if (+areasID === 0 && tipo !== 'Z') {
      GetFetch.getEventosAulasTodoAreaTipo(dataForm).then((data) => {
        setEventos(data.result);
        data?.result ? setBandera(1) : setBandera(0);
      }); 
    }

    if (+areasID !== 0 && tipo === 'Z') {
      GetFetch.getEventosAulasTodoTipoArea(dataForm).then((data) => {
        setEventos(data.result);
        data?.result ? setBandera(1) : setBandera(0);
      }); 
    }

    if (+areasID !== 0 && tipo !== 'Z') {
      GetFetch.getEventosAulasAreaID(dataForm).then((data) => {
        setEventos(data.result);
        data?.result ? setBandera(1) : setBandera(0);
      }); 
    }
  };

  const sendEmail = async () => {
    const eventosAulas = document.getElementById("eventosAulasBox").getHTML();

    var data = {
      service_id: "service_770hh1h",
      template_id: "template_zmzlh3j",
      user_id: "ErKDHp4JInDW9Ni09",
      template_params: {
        eventos: eventosAulas,
        from_name: "EVENTOS Y AULAS",
        destino: mail,
      },
    };

    const urlEmailJs = "https://api.emailjs.com/api/v1.0/email/send";
    const dataSend = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    let request;
    if (mail) {
      request = await fetch(urlEmailJs, dataSend);
    } else {
      alert("Correo obligatorio");
    }
    if (request.ok) {
      setBandera(0);
      alert("Notificación enviada con éxito a " + mail + "");
      setTimeout(() => {
        setBandera(1);
      }, 5000);
    }
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Aulas y eventos</h2>

        <div>
          <div className="row mb-3 bg-dark pb-1 text-white rounded">
            <div className="col">
              <p>Inicio</p>
              <input
                className="form-control"
                type="date"
                name="inicio"
                value={inicio}
                onChange={inicioSet}
              />
            </div>
            <div className="col">
              <p>Fin</p>
              <input
                className="form-control"
                type="date"
                name="fin"
                value={fin}
                onChange={finSet}
              />
            </div>
            <div className="col">
              <p>Área</p>
              <select name="area" className="form-control" value={areasID} onChange={areaSet}>
                <option value="0">Todos</option>
                {areas.map((el, key) => {
                  return <option value={el.id}>{el.area_name}</option>;
                })}
              </select>
            </div>
            <div className="col">
              <p>Tipo</p>
              <select
                name="tipoevento"
                className="form-control"
                value={tipo}
                onChange={tipoSet}
              >
                <option value="Z">Todos</option>
                <option value="E">Clase</option>
                <option value="F">Ayudantía</option>
                <option value="H">Clase con invitado</option>
                <option value="I">Evento interno</option>
                <option value="J">Evento externo</option>
              </select>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={btnConsultar}
            >
              {" "}
              Consultar{" "}
            </button>
          </div>
        </div>

        {bandera === 1 ? (
          <div className="ml-3">
            <input
              type="mail"
              placeholder="Email para compartir programación en pantalla"
              name="comparir"
              className="form-control border border-success mb-1"
              onChange={mailSet}
            ></input>
            <button
              type="button"
              className="btn btn-success btn-block"
              onClick={sendEmail}
            >
              Compartir
            </button>
          </div>
        ) : null}

        <div id="eventosAulasBox">
          <h3 className="mt-4 ml-3">
            Fechas. <small>Inicio:</small> {formatFecha(inicio)} / <small>Fin:</small> {formatFecha(fin)}
          </h3>
          {eventos?.length > 0 ? (
            eventos.map((elemento, key) => {
              return (
                <div
                  key={key}
                  className="row pr-3 pb-3 border border-primary m-2 rounded"
                >
                  <div className="col-sm-12 bg-dark text-white p-2 rounded mb-4">
                    {" "}
                    {elemento.Evento.toUpperCase()}
                  </div>
                  <div className="col-12 border border-primary ml-2">
                    {elemento.Ubicacion.toUpperCase()}
                  </div>
                  <div className="col-12 border border-primary ml-2">
                    {elemento.Fecha}
                  </div>
                  <div className="col-12 border border-primary ml-2">
                    {elemento.Hora}
                  </div>
                  <div className="col-12 border border-primary ml-2">
                    {elemento.Descripcion != "" ? elemento.Descripcion : "---"}
                  </div>
                  <div className="col-12 border border-primary ml-2">
                    {elemento.Solicitante}
                  </div>
                  <hr></hr>
                  <div className="col-12 mt-3">
                    {elemento.computingresources
                      ? "-- Recursos informaticos: " +
                        elemento.computingresources
                      : null}
                  </div>
                  <div className="col-12">
                    {elemento.administrativeresources
                      ? "-- Recursos administativos: " +
                        elemento.administrativeresources
                      : null}
                  </div>
                  <div className="col-12">
                    {elemento.financialresources
                      ? "-- Recursos financieros: " +
                        elemento.financialresources
                      : null}
                  </div>
                  <div className="col-12">
                    {elemento.securityresources
                      ? "--- Recursos de seguridad: " +
                        elemento.securityresources
                      : null}{" "}
                  </div>
                  <div className="col-12">
                    {elemento.comments
                      ? "--- Comentario: " + elemento.comments
                      : null}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-danger mt-4 ml-3">
              <b>No hay resultados a mostrar para las fechas y area indicada</b>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventosAulas;
